import { render, staticRenderFns } from "./ProjectManagementMetrics.vue?vue&type=template&id=e4034e12&scoped=true&"
import script from "./ProjectManagementMetrics.vue?vue&type=script&lang=js&"
export * from "./ProjectManagementMetrics.vue?vue&type=script&lang=js&"
import style0 from "./ProjectManagementMetrics.vue?vue&type=style&index=0&id=e4034e12&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4034e12",
  null
  
)

export default component.exports