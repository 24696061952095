<template>
  <div>
    <apexchart
      ref="metrics-chart"
      type="bar"
      :options="chartOptions"
      :series="series"
      :height="chartData?.height || 0"
      class="my-bottom-apex"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {isEqual} from "@/utils/helpers";
export default {
  name: "FirstAssetUploadTimeframeChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          type: "bar",
        },
        xaxis: {
          show: false,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          categories: [],
          labels: {
            show: false,
            style: {
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          crosshairs: {
            show: true,
            width: 'tickWidth'
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value;
            },
            style: {
              fontSize: '12px',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          followCursor: true,
        },
        colors: [
          '#C7DEFE',
        ],
        fill: {
          opacity: 1,
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          offsetX: 15,
          textAnchor: 'start',
          style: {
            fontSize: '12px',
            colors: ["#2F3135"]
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            borderRadiusApplication: 'around',
            horizontal: true,
            distributed: true,
            barHeight: '80%',
            dataLabels: {
              position: 'top',
            },
          }
        },
        noData: {
          text: 'No data'
        },
      },
      oldChartData: null,
    }
  },
  watch: {
    chartData: {
      async handler(val) {
        if(isEqual(val, this.oldChartData)) {
          return
        }
        this.oldChartData = JSON.parse(JSON.stringify(val))

        const chartData = this.chartData
        this.series = []
        this.series = [...val.series]
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            ...this.chartOptions.xaxis,
            categories: [...val.categories],
          },
          yaxis: {
            ...this.chartOptions.yaxis,
            max: Math.max(...val.series[0].data) + (Math.max(...val.series[0].data) * 0.1), // to make bars length 90% of the chart
          },
          dataLabels: {
            ...this.chartOptions.dataLabels,
            formatter: function (val) {
              return val + chartData.labelSufix;
            },
          },
          colors: [...chartData.colors],
          tooltip: {
            enabled: !this.series?.[0]?.data?.every(el => !el),
            shared: true,
            intersect: false,
            followCursor: true,
            custom: function ({ series, dataPointIndex }) {
              const result = series?.[0]?.[dataPointIndex]
              return `<div class="chart-tooltip-box">
                      <span>${result + chartData.labelSufix}</span>
                    </div>`;
            },
          },
          plotOptions: {
            ...this.chartOptions.plotOptions,
            bar: {
              ...this.chartOptions.plotOptions.bar,
              barHeight: chartData.barHeight
            },
          },
        }

        if(this.showModuleAverage) {
          this.toggleModuleAverage(true)
        }
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style lang="scss">
.chart-tooltip-box {
  background: white;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #000;
  font-size: 10px;
  font-family: "Inter", sans-serif;
  color: #000;
}

.my-bottom-apex {
  min-height: unset !important;
  .apexcharts-active {
    fill: rgba(220, 221, 222, 0.25) !important;
  }
  .apexcharts-series {
    path {
      filter: none !important;
    }
  }
  .apexcharts-yaxis-label {
    fill: #81848B !important;
    font-family: "Inter", sans-serif !important;
  }
}
</style>
