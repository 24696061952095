<template>
  <div>
    <div class="d-flex align-center mb-3 relative">
      <MetricsFilters
        :filter="queryParams"
        :filtersState="filtersState"
        @updateFilter="updateFilter"
        class="mr-3"
      />
      <div
        v-if="this.queryParams[this.$config.analyticsConfig.filter_slugs.metrics_date] && comparedText"
        class="text-captions-2 gray-60--text"
      >
        Compared with the past {{ comparedText }} <span v-if="comparedDates">({{comparedDates }})</span>
      </div>
      <v-progress-linear
        v-if="loadingData && projectManagementMetricsResponse && reviewAndApprovalMetricsResponse"
        indeterminate
        color="accent"
        class="absolute progress-line"
      />
    </div>
    <div v-if="loadingData && !projectManagementMetricsResponse && !reviewAndApprovalMetricsResponse" class="loading d-flex justify-center align-center">
      <v-progress-circular
          :size="100"
          color="accent"
          indeterminate
      />
    </div>
    <v-row v-else class="pa-0 ma-0">
      <v-col class="pa-0 ma-0 mr-6">
        <h2 class="text-body font-weight-semi-bold mb-3">Project Management Metrics</h2>
        <ProjectManagementMetrics :items="projectMetricsData" />
      </v-col>
      <v-col class="pa-0 ma-0">
        <h2 class="text-body font-weight-semi-bold mb-3">Review and Approval Metrics</h2>
        <ProjectManagementMetrics :items="reviewAndApprovalMetricsData" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MetricsFilters from "@/views/analytics/components/MetricsFilters.vue";
import {differenceInDays, format} from "date-fns";
import ProjectManagementMetrics from "@/views/analytics/components/metrics/ProjectManagementMetrics.vue";
import {isEqual, formatTimeToDays, toFixedFirstSignificant} from "@/utils/helpers";
export default {
  name: "MetricsSection",
  components: {
    ProjectManagementMetrics,
    MetricsFilters
  },
  data() {
    return {
      projectManagementMetricsResponse: null,
      reviewAndApprovalMetricsResponse: null,
      loadingData: false,
      filtersState: {
        metrics_date: {
          loading: false,
          search: '',
          data: [
            // {title: 'Today', id: '0'},
            // {title: 'Yesterday', id: '1'},
            {title: 'Last 7 days', id: '7'},
            {title: 'Last 30 days', id: '30'},
            {title: 'Last 3 Months', id: '90'},
            {title: 'Last 6 Months', id: '180'},
            {title: 'Last Year', id: '360'},
            {title: 'Year to date', id: 'year_to_date'},
          ],
        },
      },
      viewSectionsTypes: {
        'day': '1 day',
        'week': '7 days',
        'month': '1 month',
        'trimester': '3 months'
      },
      projectMetricsItems: [
        {
          slug: 'projects_created',
          growthRateSlug: 'projects_created_percent',
          title: 'Projects Created',
          icon: 'IconNewFile',
          valueType: 'number',
          valueToShowSlug: 'total',
          measurementLabel: () => ``,
          active: true,
          tooltipTemplate: (value) =>  `<b>${value}</b> New Projects`
        },
        {
          slug: 'projects_completed',
          growthRateSlug: 'projects_completed_percent',
          title: 'Projects Completed',
          icon: 'IconCheck',
          valueType: 'number',
          valueToShowSlug: 'total',
          measurementLabel: () => ``,
          active: false,
          tooltipTemplate: (value) =>  `<b>${value}</b> Projects Completed`
        },
        {
          slug: 'avg_project_duration',
          growthRateSlug: 'avg_project_duration_percent',
          title: 'Average Project Duration',
          icon: 'IconClockFilled',
          valueType: 'dateString',
          valueToShowSlug: 'average',
          measurementLabel: (value) => ` ${value > 1 ? 'days' : 'day'}`,
          active: false,
          growsOpposite: true,
          tooltipTemplate: (value) =>  `<b>${value}</b> ${value > 1 ? 'days' : 'day'}`
        },
      ],
      reviewApprovalMetricsItems: [
        {
          slug: 'review_time_days',
          growthRateSlug: 'review_time_days_percent',
          title: 'Review Time',
          icon: 'IconClockFilled',
          valueType: 'dateString',
          valueToShowSlug: 'average',
          measurementLabel: (value) => ` ${value > 1 ? 'days' : 'day'}`,
          active: false,
          growsOpposite: true,
          tooltipTemplate: (value) =>  `<b>${value}</b> ${value > 1 ? 'days' : 'day'}`
        },
        {
          slug: 'approval_time_days',
          growthRateSlug: 'approval_time_days_percent',
          title: 'Approval Time',
          icon: 'IconCheck',
          valueType: 'dateString',
          valueToShowSlug: 'average',
          measurementLabel: (value) => ` ${value > 1 ? 'days' : 'day'}`,
          active: false,
          tooltipTemplate: (value) =>  `<b>${value}</b> ${value > 1 ? 'days' : 'day'}`
        },
        {
          slug: 'cycle_time_days',
          growthRateSlug: 'cycle_time_days_percent',
          title: 'Review/Approval Cycle Time',
          icon: 'IconCycle',
          valueType: 'dateString',
          valueToShowSlug: 'average',
          measurementLabel: (value) => ` ${value > 1 ? 'days' : 'day'}`,
          active: false,
          growsOpposite: true,
          tooltipTemplate: (value) =>  `<b>${value}</b> ${value > 1 ? 'days' : 'day'}`
        },
        {
          slug: 'success_rate',
          growthRateSlug: 'success_rate_percent',
          title: 'Review/Approval Success Rate',
          icon: 'IconNewFile',
          valueType: 'number',
          valueToShowSlug: 'average',
          measurementLabel: () => `%`,
          active: false,
          tooltipTemplate: (value) =>  `<b>${value}%</b>`
        },
      ],
    }
  },
  computed: {
    queryParams() {
      return {
        metrics_date: this.$route.query.metrics_date || null,
        date: this.$route.query.date || null,
        spaces: Array.isArray(this.$route.query.spaces) ? this.$route.query.spaces.map(el => Number(el)) : this.$route.query.spaces ? [Number(this.$route.query.spaces)]  : [],
      }
    },
    comparedText() {
      if(!this.projectManagementMetricsResponse?.filters) {
        return ''
      }
      const pastDays = differenceInDays(new Date(this.projectManagementMetricsResponse?.filters?.to), new Date(this.projectManagementMetricsResponse?.filters?.from)) || ''
      return `${pastDays} days`
    },
    comparedDates() { // same date as for reviewAndApprovalMetricsResponse, so use any of response
      if(!this.projectManagementMetricsResponse?.filters) {
        return null
      }
      return `${format(new Date(this.projectManagementMetricsResponse.filters.from).setHours(0,0,0,0), 'MMM dd, yyyy')} - ${format(new Date(this.projectManagementMetricsResponse.filters.to).setHours(0,0,0,0), 'MMM dd, yyyy')}`
    },
    projectMetricsData() {
      return this.setChartData(this.projectMetricsItems, this.projectManagementMetricsResponse)
    },
    reviewAndApprovalMetricsData() {
      return this.setChartData(this.reviewApprovalMetricsItems, this.reviewAndApprovalMetricsResponse)
    }
  },
  watch: {
    'queryParams.date': {
      async handler(val) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          if(val && val !== this.queryParams.metrics_date) {
            await this.$router.push({query: {
                ...this.$route.query,
                metrics_date: val
              }})
            await this.getInfo()
          }
        }, 600)
      }
    },
    'queryParams.spaces': {
      async handler(val, oldVal) {
        if(!isEqual(val, oldVal)) {
          clearTimeout(this.debounce)
          this.debounce = setTimeout(async () => {
            if(this.$route.query.modules?.length) {
              await this.$router.push({query: {
                  ...this.$route.query,
                }})
            }
            await this.getInfo()
          }, 600)
        }
      }
    },
  },
  async mounted() {
    await this.setDefaultFilters()
    await this.getInfo(false)
  },
  methods: {
    updateFilter() {
      this.getInfo(false)
    },
    async getInfo() {
      try {
        this.loadingData = true

        await Promise.all([this.fetchReviewAndApprovalMetrics(), this.fetchProjectManagementMetrics()])

      } catch (error) {
        console.error(error);
      } finally {
        this.loadingData = false
      }
    },
    async setDefaultFilters() {
      const defaultQuery = {}
      if(!this.queryParams.metrics_date) {
        defaultQuery.metrics_date = this.queryParams.date || '30'
      }

      if(Object.keys(defaultQuery).length) {
        await this.$router.push({
          query: {
            ...this.$route.query,
            ...defaultQuery
          }
        })
      }
    },
    async fetchProjectManagementMetrics() {
      try {
        const params = {
          spaces: this.queryParams.spaces,
          date: this.queryParams.metrics_date,
        }
        this.projectManagementMetricsResponse = await this.$api.analytics.getProjectManagementMetrics(params)
      } catch (error) {
        console.error(error);
        throw error
      }
    },
    async fetchReviewAndApprovalMetrics() {
      try {
        const params = {
          spaces: this.queryParams.spaces,
          date: this.queryParams.metrics_date,
        }
        this.reviewAndApprovalMetricsResponse = await this.$api.analytics.getReviewAndApprovalMetrics(params)
      } catch (error) {
        console.error(error);
        throw error
      }
    },
    setChartData(templateData, data) {
      return templateData.map(item => {
        let itemTotalResult = 0
        let itemAvgResult = 0
        let seria = [];

        if(item.valueType === 'number') {
          itemTotalResult = data?.data?.reduce((acc, el) => {
            acc = acc + Number(el[item.slug] || 0)
            return acc
          }, 0) || 0

          itemAvgResult = (data?.data?.reduce((acc, el) => {
            return acc + Number(el[item.slug] || 0)
          }, 0) / data?.data?.filter(el => el[item.slug] && el[item.slug] !== '0')?.length) || 0

          seria = data?.data?.map(el => {
            return toFixedFirstSignificant(Number(el[item.slug] || 0))
          }) || []
        } else if (item.valueType === 'dateString') {
          itemTotalResult = data?.data?.reduce((acc, el) => {
            acc = acc + formatTimeToDays(el[item.slug] || 0)
            return acc
          }, 0) || 0

          itemAvgResult = (data?.data?.reduce((acc, el) => {
            return acc + formatTimeToDays(el[item.slug] || 0)
          }, 0) / data?.data?.filter(el => el[item.slug] && formatTimeToDays(el[item.slug] || 0))?.length) || 0

          seria = data?.data?.map(el => {
            const result = formatTimeToDays(el[item.slug] || 0)
            return toFixedFirstSignificant(Number(result || 0))
          }) || []
        }
        itemTotalResult = itemTotalResult.toFixed(Number.isInteger(itemTotalResult) ? 0 : 1)
        itemAvgResult = toFixedFirstSignificant(Number(itemAvgResult || 0))


        const categories = data?.data?.map((el, idx) => {
          if(data.filters.interval === this.viewSectionsTypes.day) {
            const date = new Date(el.period_start_date).setHours(0, 0, 0, 0)
            return format(date, 'EEE')
          } else if(data.filters.interval === this.viewSectionsTypes.week) {
            return `Week ${idx + 1}`
          } else if(data.filters.interval === this.viewSectionsTypes.month || data.filters.interval === this.viewSectionsTypes.trimester) {
            const formatTemplate = "MMM"
            const startMonth = format(new Date(el.period_start_date).setHours(0, 0, 0, 0), formatTemplate)
            const endMonth = format(new Date(el.period_end_date).setHours(0, 0, 0, 0), formatTemplate)
            return startMonth === endMonth ? startMonth : `${startMonth} - ${endMonth}`
          }
          return idx + 1
        }) || []

        const growthRate = toFixedFirstSignificant((data?.data?.reduce((acc, el) => {
          return acc + Number(el[item.growthRateSlug] || 0)
        }, 0) / data?.data?.filter(el => el[item.growthRateSlug] && el[item.growthRateSlug] !== '0')?.length) || 0)

        if(seria.every(el => el === 0)) {
          seria = []
        }
        return {
          ...item,
          total: itemTotalResult,
          average: itemAvgResult,
          series: [{data: [...seria]}],
          categories: categories,
          growthRate: growthRate,
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.progress-line {
  bottom: -4px;
}
.loading {
  min-height: 1176px;
}
</style>
